
import lodash from "lodash";
import { Component, Emit, Vue, Watch, VModel } from "vue-property-decorator";
import ActionBar from "@/components/trainings/ActionBar.vue";
import {
  getElementType,
  Lesson,
  LessonMetadata,
  MediaEntity,
  Owner,
  Question,
  Quiz,
  Sublesson,
  TrainingElement,
  TrainingElementReference,
} from "shared-alva/models";
import { PickerElement } from "@/models";
import { Training, MediaMetadata } from "shared-alva/models";
import { languages } from "shared-alva/languages";

import draggable from "vuedraggable";
import ElementPicker from "@/components/ElementPicker.vue";
import ElementPreview from "@/components/ElementPreview.vue";
import ElementDropZone from "@/components/ElementDropZone.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import OsdiCloseButton from "shared-alva/components/buttons/OsdiCloseButton.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiDeleteButton from "shared-alva/components/buttons/OsdiDeleteButton.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import OsdiMenu from "shared-alva/components/menus/OsdiMenu.vue";
import { hasPermission } from "@/authentication/authentication.service";
import TrainingsModule from "@/store/modules/trainings-store_v2";
import sublessonStore from "@/store/modules/sublessons-store_v2";
import lessonStore from "@/store/modules/lessons-store_v2";
import PublishDialog from "../PublishDialog.vue";
import MediaLibrary from "@/components/media/MediaLibrary.vue";
import TargetSettings from "@/components/TargetSettings.vue";
import { TableMenu } from "@/models";
import { v4 as uuid } from "uuid";
import {
  exportTrainingTranslationFile,
  importTrainingTranslationFile,
} from "@/translation.service";
import { ImportTrainingTypes } from "@/models";
import { ROUTES } from "@/router";
import { valid } from "semver";
import { deleteTraining } from "shared-alva/api/graphql/mutations";
import QuizModule from "@/store/modules/quiz-store";
import { getModule } from "vuex-module-decorators";
import QuestionModule from "@/store/modules/questions-store";

@Component({
  components: {
    OsdiTextArea,
    ElementDropZone,
    ElementPreview,
    ActionBar,
    ElementPicker,
    draggable,
    OsdiTextField,
    OsdiButton,
    OsdiConfirmDialog,
    OsdiCloseButton,
    OsdiSnackbar,
    OsdiIconButton,
    OsdiDeleteButton,
    OsdiMenu,
    PublishDialog,
    MediaLibrary,
    TargetSettings,
    OsdiTooltipButton,
  },
})
export default class TrainingDetail extends Vue {
  isDropZoneHighlighted = false;
  isMounted = false;
  private isUploading = false;
  $refs!: {
    confirmDialog: OsdiConfirmDialog;
    snackbar: OsdiSnackbar;
    elementPicker: ElementPicker;
    confirmDeleteDialog: OsdiConfirmDialog;
    translationFileUpload: HTMLInputElement;
  };
  private clonedTraining?: Training;
  snackbarMessage: string = "";
  quizStore = getModule(QuizModule);
  questionStore = getModule(QuestionModule);

  valid = false;
  unsavedChanges = false;
  resolvedMedia: MediaEntity = {} as MediaEntity;
  private titleRules = [(v: string) => v.length <= 80 || "max 80"];
  drawer = false;
  tabs = null;
  private languages: languages[] = Object.values(languages);
  private selectedLanguage: languages = this.$i18n.locale as languages;

  showSaveButton = false;
  private showExportButton = false;
  private showDeleteButton = false;
  showPublish = false;
  showMediaLibrary: boolean = false;
  hasError = false;

  pickerElements: PickerElement[] = [];
  pickedElements: PickerElement[] = [];
  private firstOpen = true;
  public elements: {
    training: Training;
    lessons: Lesson[];
    sublessons: Sublesson[];
    questions: Question[];
    quizzes: Quiz[];
  } | null = null;

  private updated = 0;
  moreActions: TableMenu[] = [
    { label: "Export translation file", icon: "mdi-export", action: this.exportTranslationFile },
    { label: "Import translation file", icon: "mdi-import", action: this.openFilePicker },
  ];

  public training: Training = {} as Training;

  get isLoading() {
    return (
      lessonStore.isLoading ||
      sublessonStore.isLoading ||
      TrainingsModule.isLoading ||
      !this.isMounted
    );
  }

  get hasThumbnail() {
    return this.training.thumbnail !== undefined && this.training.thumbnail !== null;
  }

  get getOverflow() {
    return this.drawer ? "initial" : "hidden";
  }

  // @VModel()
  // training!: Training;

  @Watch("training", { deep: true })
  onTrainingChanged() {
    this.unsavedChanges = !lodash.isEqual(this.training, this.clonedTraining) && !this.firstOpen;
    this.firstOpen = false;
  }

  @Watch("pickedElements", { deep: true })
  async updateTrainingElements() {
    this.hasError = false;
    this.training.elements = this.pickedElements.map((el) => {
      return {
        owner: el.owner,
        id: el.id,
        language: el.language,
        version: el.version,
      } as unknown as TrainingElement;
    });

    if (this.clonedTraining) {
      this.clonedTraining.elements = this.clonedTraining.elements.map((el) => {
        return {
          owner: el.owner,
          id: el.id,
          language: el.language,
          version: el.version,
        } as unknown as TrainingElement;
      });
    }

    try {
      // check if there is a version, if there is now throw error
      const lessons = this.training.elements as Lesson[];
      for (let i = 0; i < lessons.length; i++) {
        if (lessons[i].language !== this.training.language) {
          this.hasError = true;
        }

        const availableVersion = await lessonStore.getAvailableVersions(lessons[i]);
        if (availableVersion.length === 0 && !(lessons[i].description === "Not found")) {
          throw new Error("No language version available");
        }
      }
    } catch (e) {
      this.hasError = true;
    }
  }

  @Emit()
  public async close() {
    if (this.unsavedChanges) {
      TrainingsModule.resetTraining({
        id: this.training.id,
        language: this.training.language as languages,
        version: this.training.version,
        owner: this.training.owner,
      });
    }

    this.$router.push({ name: ROUTES.SmartCollegeTrainings });
  }

  async saveAndClose() {
    await this.save();
    await this.close();
  }

  async created() {
    const language = (this.$route.query.language as string) || "";
    const version = (this.$route.query.version as string) || "";
    const training = await TrainingsModule.fetchTraining({
      owner: { siteId: "global", tenant: "fettecompacting" },
      id: this.$route.params.trainingId || "",
      language: language,
      version: version,
    });
    this.training = (training as Training) || {};

    if (!training?.thumbnail) {
      this.resolvedMedia.signedThumbnailUrl = "";
    }

    this.clonedTraining = lodash.cloneDeep(this.training);

    const fetchPromises = [
      sublessonStore.getAllSublessonVersions(this.training.language as languages),
      lessonStore.getAllLessonVersions(this.training.language as languages),
    ];
    this.showSaveButton =
      (await hasPermission("updateTraining")) && this.training.status !== "published";
    this.showExportButton = await hasPermission("readTraining");
    this.showDeleteButton =
      (await hasPermission("deleteTraining")) && this.training.status !== "published";

    await Promise.all(fetchPromises);
    const sublessons = sublessonStore.sublessonList[this.training.language].map(
      (el: any) => el.latest
    );
    const lessons = lessonStore.lessonList[this.training.language].map((el: any) => el.latest);
    this.pickerElements = [
      ...lessons.map((l: Lesson) => {
        const pickerElement: PickerElement = {
          owner: l.owner,
          id: l.id,
          description: l.description || "",
          language: l.language,
          type: "lesson",
          version: l.version,
          title: l.title,
          element: l.element,
          parentLanguage: this.training.language,
          status: l.status!,
        };
        return pickerElement;
      }),
    ];

    const train = (await TrainingsModule.getTraining(this.training)) || this.training;
    this.pickedElements = train.elements.map((el) => {
      const ref: PickerElement = {
        owner: el.owner,
        id: el.id,
        description: "",
        language: el.language,
        version: el.version,
        type: getElementType(el),
        title: (el as Lesson).title,
        element: getElementType(el) != "training" ? (el as Lesson).element : "",
        parentLanguage: this.training.language,
        status: el.status || "unknown",
      };
      return ref;
    });
  }

  async mounted() {
    await this.resolveMedia();
    this.isMounted = true;
  }

  highlightDropZone(highlight: boolean) {
    this.isDropZoneHighlighted = highlight;
  }

  async clear() {
    // this.training.thumbnail = undefined;
    // await TrainingsModule.saveTraining(this.training);
  }

  @Watch("training")
  async resolveMedia() {
    if (!this.hasThumbnail) {
      return;
    } else if (this.training.thumbnail) {
      this.resolvedMedia = this.training.thumbnail;
    }
  }

  onDeleteMediaAtIndex() {
    this.training.thumbnail = undefined;
    this.resolveMedia();
  }

  async save() {
    let hasError = false;

    try {
      // check if there is a version, if there is now throw error
      const lessons = this.training.elements as Lesson[];
      for (let i = 0; i < lessons.length; i++) {
        const availableVersion = await lessonStore.getAvailableVersions(lessons[i]);
        if (availableVersion.length === 0 && !(lessons[i].description === "Not found")) {
          hasError = true;
          throw new Error("No language version available");
        }
      }

      if (hasError) {
        throw new Error("No version available");
      }

      await TrainingsModule.saveTraining(this.training);
      this.$refs.confirmDialog.close();
      this.unsavedChanges = false;
      this.clonedTraining = lodash.cloneDeep(this.training);
      this.$refs.snackbar.open();
      this.snackbarMessage = "Saved!";
    } catch (error) {
      this.$refs.snackbar.open();
      this.snackbarMessage = error as string;
    }
  }

  async deleteTraining() {
    this.$refs.confirmDeleteDialog.open({
      confirmCallback: async () => {
        await TrainingsModule.deleteTraining(this.training);
        this.close();
        this.$router.go(-1);
      },
      message: this.$t("smartcollege.list.deleteNotification.message", {
        name: this.training.title,
      }).toString(),
    });
  }

  async exportTraining() {
    const languageToExport = this.selectedLanguage;
    const trainingId = this.training.id;
  }

  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  /**
   * @see app.vue
   **/
  public onCloseDialog() {
    if (this.unsavedChanges) {
      this.$refs.confirmDialog.open();
      return;
    }
    this.close();
    this.$router.push({
      path: `/trainings`,
    });
  }

  onElementRemovedFromDropZone() {
    this.$refs.elementPicker.refreshDraggables();
  }

  get canBeSaved() {
    return (
      this.unsavedChanges &&
      this.training.status !== "published" &&
      this.training.elements.every((s) => {
        return s.version != "" && s.language != "";
      })
    );
  }

  get canBePublished() {
    return !this.unsavedChanges && this.training.status !== "published";
  }

  async publish() {
    const elementPromises = this.training.elements.map((s) => {
      if (getElementType(s) == "sublesson") {
        return sublessonStore.getSublesson({
          owner: s.owner,
          id: s.id,
          language: s.language,
          version: s.version,
        }) as Promise<TrainingElement>;
      } else if (getElementType(s) == "lesson") {
        return lessonStore.getLesson({
          owner: s.owner,
          id: s.id,
          language: s.language,
          version: s.version,
        }) as Promise<TrainingElement>;
      }
    });
    const elements = await Promise.all(elementPromises);

    if (
      elements.every((el: any) => {
        return el.status == "published";
      })
    ) {
      this.showPublish = true;
    }
  }

  onPublishCanceled() {
    this.showPublish = false;
  }
  onPublished() {
    this.showPublish = false;
    this.close();
  }

  openMediaLibrary() {
    this.showMediaLibrary = true;
  }
  onCloseMediaLibrary() {
    this.showMediaLibrary = false;
  }

  async onNewMediaSelected(media: MediaMetadata) {
    this.training.thumbnail = {
      owner: { tenant: "fettecompacting", siteId: "global" },
      uuid: media.uuid,
      mimeType: media.mimeType,
      signedUrl: media.signedUrl,
      signedThumbnailUrl: media.signedThumbnailUrl,
      fileExtension: media.fileExtension || "",
    };

    this.showMediaLibrary = false;
    await this.resolveMedia();
  }

  async getQuizElements(lessons: Lesson[]) {
    const quizzesEls: Quiz[] = [];
    let questionsEls: Question[] = [];

    for (const l of lessons) {
      if (l.quiz) {
        const quiz = (await this.quizStore.getQuiz(l.quiz)) as Quiz;
        quizzesEls.push(quiz);
        if (quiz) {
          questionsEls = [...questionsEls, ...quiz.questions];
        }
      }
    }

    return { quizzesEls, questionsEls };
  }

  async importTranslationFileFunc(event: any) {
    let fileData = event.target.files[0];

    let sl: ImportTrainingTypes = {} as ImportTrainingTypes;

    let lessons: Lesson[] = [];

    for (const el of this.training.elements) {
      const l = await lessonStore.getLesson(el as Lesson);
      if (l) lessons.push(l);
    }

    let quizzesIn: Quiz[] = [];
    let questionsIn: Question[] = [];

    if (lessons) {
      const qElements = await this.getQuizElements(lessons);
      quizzesIn = [...qElements.quizzesEls];
      questionsIn = [...qElements.questionsEls];
      const sublessonList: Sublesson[] = lessons.map((l) => l.sublessons).flat();

      sl = (await importTrainingTranslationFile(
        this.training,
        fileData,
        sublessonList,
        lessons,
        quizzesIn,
        questionsIn,
        "XLSX"
      )) as ImportTrainingTypes;
    }

    const srcLang = fileData.name.split("_")[0];

    //await TrainingsModule.fetchTrainings()
    await TrainingsModule.getAllTrainingVersions(srcLang);
    await lessonStore.getAllLessonVersions(srcLang);
    await sublessonStore.getAllSublessonVersions(srcLang);
    await this.quizStore.getAllQuizzesVersions(srcLang);
    await this.questionStore.getAllQuestionsVersions(srcLang);

    const { training, sublessons, lessons: imLessons, questions, quizzes } = sl;

    this.elements = { training, sublessons, lessons: imLessons, questions, quizzes };

    let owner: Owner = {} as Owner;
    let language: languages = languages.en;
    for (const s of sublessons) {
      s.status = "draft";
      if (!owner.tenant) {
        owner = s.owner;
      }

      if (language) {
        language = s.language as languages;
      }

      const sublessonVersions = await sublessonStore.getAllPublishedVersions({
        language: srcLang,
        id: s.id,
        owner: s.owner,
        version: s.version,
      });

      if (!(sublessonVersions.length && sublessonVersions.includes(s.version))) {
        await sublessonStore.saveSublesson(s);
      }
    }

    for (const q of questions) {
      q.status = "draft";
      if (!owner.tenant) {
        owner = q.owner;
      }

      if (language) {
        language = q.language as languages;
      }

      const questionVersions = await this.questionStore.getAllPublishedVersions({
        language: srcLang,
        id: q.id,
        owner: q.owner,
        version: q.version,
      });

      if (!(questionVersions.length && questionVersions.includes(q.version))) {
        await this.questionStore.saveQuestion(q);
      }
    }

    for (const q of quizzes) {
      q.status = "draft";

      for (let i = 0; i < q.questions.length; i++) {
        q.questions[i] = (await this.questionStore.getQuestion({
          language,
          owner: owner,
          id: q.questions[i].id,
          version: q.questions[i].version,
        })) as Question;
      }

      const quizVersions = await this.quizStore.getAllPublishedVersions({
        language: srcLang,
        id: q.id,
        owner: q.owner,
        version: q.version,
      });

      if (!(quizVersions.length && quizVersions.includes(q.version))) {
        await this.quizStore.saveQuiz(q);
      }
    }

    for (const l of imLessons) {
      l.status = "draft";

      for (let i = 0; i < l.sublessons.length; i++) {
        l.sublessons[i] = (await sublessonStore.getSublesson({
          owner: l.sublessons[i].owner,
          language,
          id: l.sublessons[i].id,
          version: l.sublessons[i].version,
        })) as Sublesson;
      }

      if (l.quiz) {
        l.quiz = {
          owner: l.quiz.owner,
          language,
          id: l.quiz.id,
          version: l.quiz.version,
        } as TrainingElementReference;
      }

      const lessonVersions = await lessonStore.getAllPublishedVersions({
        language: srcLang,
        id: l.id,
        owner: l.owner,
        version: l.version,
      });

      if (!(lessonVersions.length && lessonVersions.includes(l.version))) {
        await lessonStore.saveLesson(l);
      }
    }

    for (let i = 0; i < training.elements.length; i++) {
      training.elements[i] = (await lessonStore.getLesson({
        owner: training.elements[i].owner,
        language: language,
        id: training.elements[i].id,
        version: training.elements[i].version,
      })) as Lesson;
    }
    const trainingVersions = await TrainingsModule.getAllPublishedVersions({
      language: srcLang,
      id: training.id,
      owner: training.owner,
      version: training.version,
    });

    if (!(trainingVersions && trainingVersions.includes(training.version))) {
      await TrainingsModule.saveTraining(training);
    }

    this.updated = this.updated + 1;
  }

  async importTranslationFile(event: any) {
    try {
      await this.importTranslationFileFunc(event);

      this.$refs.snackbar.open();
      this.snackbarMessage = "Training imported successfully";
      this.$router.go(0);
    } catch (e) {
      this.$refs.snackbar.open();

      this.snackbarMessage = "Error importing training";

      if (this.elements) {
        const { training, sublessons, lessons, questions, quizzes } = this.elements;
        for (const sublesson of sublessons) {
          await sublessonStore.deleteSublesson({
            owner: sublesson.owner,
            id: sublesson.id,
            language: sublesson.language as languages,
            version: sublesson.version,
          });
        }

        for (const q of questions) {
          await this.questionStore.deleteQuestion({
            owner: q.owner,
            id: q.id,
            language: q.language as languages,
            version: q.version,
          });
        }

        for (const q of quizzes) {
          await this.questionStore.deleteQuestion({
            owner: q.owner,
            id: q.id,
            language: q.language as languages,
            version: q.version,
          });
        }

        for (const lesson of lessons) {
          await lessonStore.deleteLesson({
            id: lesson.id,
            language: lesson.language as languages,
            version: lesson.version,
          });
        }

        await TrainingsModule.deleteTraining({
          id: training.id,
          language: training.language as languages,
          version: training.version,
        });
      }
    }
  }

  openFilePicker() {
    this.$refs.translationFileUpload!.click();
  }

  async exportTranslationFile() {
    exportTrainingTranslationFile(this.training);
  }
}
