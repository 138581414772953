import {
  TrainingElement,
  Sublesson,
  Slide,
  Warning,
  Hint,
  MediaEntity,
  Lesson,
  Training,
  getElementType,
  Quiz,
  Question,
  Choice,
} from "shared-alva/models";
import zipcelx from "zipcelx";
import readXlsxFile from "read-excel-file";
import ToMarkdownService from "turndown";
import ToHtmlService from "markdown-it";
import TrainingsModule from "@/store/modules/trainings-store_v2";
import LessonsModule from "@/store/modules/lessons-store_v2";
import { ImportTrainingTypes } from "./models";
import QuizModule from "./store/modules/quiz-store";
import { getModule } from "vuex-module-decorators";

type TranslationFileType = `XLSX`;

export async function exportTrainingTranslationFile(element: TrainingElement) {
  const quizModule = getModule(QuizModule);
  const training = TrainingsModule.trainings.find((t) => t.id == element.id);
  const lessons = [];

  if (training && training.elements.length) {
    const lessonElements: TrainingElement[] = training?.elements;
    for (const e of lessonElements) {
      const l = await LessonsModule.getLesson(e as Lesson);
      lessons.push(l);
    }
  }

  const quizzes: Quiz[] = [];
  const questions: Question[] = [];

  if (lessons && lessons.length) {
    for (const l of lessons) {
      if (l && l.quiz) {
        const q = (await quizModule.getQuiz(l.quiz)) as Quiz;
        quizzes.push(q);
        if (q) {
          for (const question of q.questions) {
            questions.push(question);
          }
        }
      }
    }
  }

  if (lessons && training) {
    await Promise.all(lessons).then((result) => {
      if (result !== undefined) {
        const sublessons = result
          .map((l) => {
            return l?.sublessons.map((s) => {
              return s;
            });
          })
          .flat();

        exportTranslationFile(
          training,
          "XLSX",
          training,
          result as Lesson[],
          sublessons as Sublesson[],
          quizzes,
          questions
        );
      }
    });
  }
}

export function exportTranslationFile(
  element: TrainingElement,
  fileType: TranslationFileType,
  training?: Training,
  lessons?: Lesson[],
  sublessons?: Sublesson[],
  quizzes?: Quiz[],
  questions?: Question[]
) {
  let lessonsTranslation: Record<string, string> = {};
  let sublessonTranslation: Record<string, string> = {};
  let quizTranslation: Record<string, string> = {};
  let questionTranslation: Record<string, string> = {};
  if (lessons === undefined && training === undefined) {
    const translation = createTranslationRecord(element);
  }

  let translation = createTranslationRecord(element);

  if (training && lessons && sublessons) {
    lessons.forEach((l) => {
      lessonsTranslation = { ...lessonsTranslation, ...createTranslationRecord(l) };
    });

    sublessons.forEach((s) => {
      sublessonTranslation = { ...sublessonTranslation, ...createTranslationRecord(s) };
    });

    translation = { ...translation, ...lessonsTranslation, ...sublessonTranslation };
  }

  if (quizzes && questions) {
    quizzes.forEach((q) => {
      quizTranslation = { ...quizTranslation, ...createTranslationRecord(q) };
    });

    questions.forEach((q) => {
      questionTranslation = { ...questionTranslation, ...createTranslationRecord(q) };
    });

    translation = { ...translation, ...quizTranslation, ...questionTranslation };
  }

  translationRecordToXlsx(element, translation);
}

export function createTranslationRecord(element: TrainingElement): Record<string, string> {
  let translationRecord: Record<string, string> = {};

  switch (getElementType(element)) {
    case `training`:
      translationRecord = createTrainingTranslationRecord(element as Training);
      break;
    case `lesson`:
      translationRecord = createLessonTranslationRecord(element as Lesson);
      break;
    case `sublesson`:
      translationRecord = createSublessonLanguageRecord(element as Sublesson);
      break;
    case `quiz`:
      translationRecord = createQuizTranslationRecord(element as Quiz);
      break;
    default:
      translationRecord = createQuestionTranslationRecord(element as Question);

      break;
  }

  return replaceTagsInValues(translationRecord);
}

export async function importTranslationFile(
  baseElement: TrainingElement,
  translationFileContent: File,
  filetype: TranslationFileType
): Promise<TrainingElement | undefined> {
  let translationRecord: Record<string, string> = {};
  if (filetype == `XLSX`) translationRecord = await xlsxToObject(translationFileContent);
  else {
    throw `Not implemented`;
  }
  translationRecord = addTagsToValues(translationRecord);

  if (
    translationRecord[`${baseElement.id}_version`] != baseElement.version ||
    translationRecord[`${baseElement.id}_language`] != baseElement.language.toString()
  ) {
    throw `Element changed`;
  }

  switch (getElementType(baseElement)) {
    case `training`:
      return importTrainingTranslationRecord(baseElement as Training, translationRecord);
    case `sublesson`:
      return importSublessonTranslationRecord(baseElement as Sublesson, translationRecord);
    case `lesson`:
      return importLessonTranslationRecord(baseElement as Lesson, translationRecord);

    default:
      return undefined;
  }
}

export async function importTrainingTranslationFile(
  baseElement: TrainingElement,
  translationFileContent: File,
  allSublessons: Sublesson[],
  allLessons: Lesson[],
  allQuizzes: Quiz[],
  allQuestions: Question[],
  filetype: TranslationFileType
): Promise<ImportTrainingTypes> {
  let translationRecord: Record<string, string> = {};
  const translationRecordOrg: { [key: string]: Record<string, string> } = {};
  if (filetype == `XLSX`) translationRecord = await xlsxToObject(translationFileContent);
  else {
    throw `Not implemented`;
  }
  translationRecord = addTagsToValues(translationRecord);

  // use only the ones with the same element type and id
  for (const key in translationRecord) {
    const split = key.split("_");
    const keys = Object.keys(translationRecord);
    const keysToAdd = keys.filter((k) => {
      return k.includes(`${split[0]}_${split[1]}`);
    });

    const newTranslationRecord: Record<string, string> = {};
    keysToAdd.forEach((k) => {
      newTranslationRecord[k] = translationRecord[k];
    });

    translationRecordOrg[`${split[0]}_${split[1]}`] = {
      ...newTranslationRecord,
      ...translationRecordOrg[`${split[0]}_${split[1]}`],
    };
  }

  let training: Training = {} as Training;
  const lessons: Lesson[] = [];
  const sublessons: Sublesson[] = [];
  const quizzes: Quiz[] = [];
  const questions: Question[] = [];

  for (const key in translationRecordOrg) {
    switch (key.split("_")[0]) {
      case `training`:
        training = importTrainingTranslationRecord(
          baseElement as Training,
          translationRecordOrg[key]
        );
      case `sublesson`:
        const elementSublesson = allSublessons.find((s) => s.id == key.split("_")[1]);
        if (elementSublesson) {
          sublessons.push(
            importSublessonTranslationRecord(elementSublesson, translationRecordOrg[key])
          );
        }
      case `lesson`:
        const elementLesson = allLessons.find((l) => l.id == key.split("_")[1]);
        if (elementLesson) {
          lessons.push(importLessonTranslationRecord(elementLesson, translationRecordOrg[key]));
        }
      case `question`:
        const elementQuestion = allQuestions.find((q) => q.id === key.split("_")[1]);
        if (elementQuestion) {
          questions.push(
            importQuestionTranslationRecord(elementQuestion, translationRecordOrg[key])
          );
        }
      case `quiz`:
        const elementQuiz = allQuizzes.find((q) => q.id === key.split("_")[1]);
        if (elementQuiz) {
          quizzes.push(importQuizTranslationRecord(elementQuiz, translationRecordOrg[key]));
        }
    }
  }

  sublessons.forEach((s) => {
    s.status = `draft`;
  });

  lessons.forEach((l) => {
    l.status = `draft`;
  });

  quizzes.forEach((q) => {
    q.status = `draft`;
  });

  questions.forEach((q) => {
    q.status = `draft`;
  });

  training.status = `draft`;

  return {
    training,
    lessons,
    sublessons,
    quizzes,
    questions,
  };
}

function removeTags(value?: string): string {
  if (value) return value.replace(/<\/?[^>]+(>|$)/g, "");
  return "";
}

export function createSublessonLanguageRecord(sublesson: Sublesson): Record<string, string> {
  const out: Record<string, string> = {};

  out[`sublesson_${sublesson.id}_language`] = `${sublesson.language}`;
  out[`sublesson_${sublesson.id}_version`] = `${sublesson.version}`;
  out[`sublesson_${sublesson.id}_lastUpdated`] = `${sublesson.lastUpdated.toString()}`;

  out[`sublesson_${sublesson.id}_title`] = sublesson.title;
  out[`sublesson_${sublesson.id}_element`] = sublesson.element;
  out[`sublesson_${sublesson.id}_description`] = sublesson.description;

  if (sublesson.slides && sublesson.slides.length > 0) {
    sublesson.slides.forEach((s: Slide) => {
      out[`sublesson_${sublesson.id}_slide_${s.id}_title`] = s.title;
      out[`sublesson_${sublesson.id}_slide_${s.id}_bodyText`] = s.bodyText;

      if (s.media && s.media.length > 0) {
        s.media.forEach((m: MediaEntity, i: number) => {
          out[`sublesson_${sublesson.id}_slide_${s.id}_media_${i}_caption`] = m.caption ?? ``;
          out[`sublesson_${sublesson.id}_slide_${s.id}_media_${i}_badge`] = m.badge ?? ``;
        });
      }

      if (s.secondaryMedia && s.secondaryMedia.length > 0) {
        s.secondaryMedia.forEach((m: MediaEntity, i: number) => {
          out[`sublesson_${sublesson.id}_slide_${s.id}_secondaryMedia_${i}_caption`] =
            m.caption ?? ``;
          out[`sublesson_${sublesson.id}_slide_${s.id}_secondaryMedia_${i}_badge`] = m.badge ?? ``;
        });
      }

      if (s.hints && s.hints.length > 0) {
        s.hints.forEach((h: Hint) => {
          out[`sublesson_${sublesson.id}_slide_${s.id}_hint_${h.uuid}_text`] = h.text;
        });
      }

      if (s.warnings && s.warnings.length > 0) {
        s.warnings.forEach((w: Warning) => {
          out[`sublesson_${sublesson.id}_slide_${s.id}_warning_${w.uuid}_text`] = w.description;
          out[`sublesson_${sublesson.id}_slide_${s.id}_warning_${w.uuid}_source`] = w.source;
        });
      }
    });
  }
  return out;
}

export function importQuizTranslationRecord(quiz: Quiz, translation: Record<string, string>): Quiz {
  return {
    ...quiz,
    language: translation[`quiz_${quiz.id}_language`],
    version: translation[`quiz_${quiz.id}_version`],
    title: translation[`quiz_${quiz.id}_title`],
    element: translation[`quiz_${quiz.id}_element`],
    description: translation[`quiz_${quiz.id}_description`],
  };
}

export function importQuestionTranslationRecord(
  question: Question,
  translation: Record<string, string>
): Question {
  return {
    ...question,
    version: translation[`question_${question.id}_version`],
    language: translation[`question_${question.id}_language`],
    element: translation[`question_${question.id}_element`],
    title: translation[`question_${question.id}_title`],
    text: translation[`question_${question.id}_text`],
    description: translation[`question_${question.id}_description`],
    actionLabel: translation[`question_${question.id}_actionLabel`],
    choices: question.choices.length
      ? question.choices.map((c) => {
          return {
            ...c,
            additionalInfo: translation[`question_${question.id}_choice_${c.id}_additionalInfo`],
            language: translation[`question_${question.id}_choice_${c.id}_language`],
            textValue: c.textValue
              ? translation[`question_${question.id}_choice_${c.id}_textValue`]
              : undefined,
          } as Choice;
        })
      : [],
  };
}

export function importTrainingTranslationRecord(
  training: Training,
  translation: Record<string, string>
): Training {
  return {
    ...training,
    title: translation[`training_${training.id}_title`],
    description: translation[`training_${training.id}_description`],
    language: translation[`training_${training.id}_language`],
    version: translation[`training_${training.id}_version`],
  };
}

export function createQuestionTranslationRecord(question: Question): Record<string, string> {
  const out: Record<string, string> = {};
  out[`question_${question.id}_version`] = `${question.version}`;
  out[`question_${question.id}_language`] = `${question.language}`;
  out[`question_${question.id}_element`] = `${question.element}`;
  out[`question_${question.id}_title`] = `${question.title}`;
  out[`question_${question.id}_text`] = `${question.text}`;
  out[`question_${question.id}_description`] = `${question.description}`;
  out[`question_${question.id}_actionLabel`] = `${question.actionLabel}`;

  question.choices.forEach((c: Choice) => {
    out[`question_${question.id}_choice_${c.id}_additionalInfo`] = `${c.additionalInfo}`;
    out[`question_${question.id}_choice_${c.id}_language`] = `${c.language}`;
    if (c.textValue) {
      out[`question_${question.id}_choice_${c.id}_textValue`] = `${c.textValue}`;
    }
  });

  return out;
}

export function createQuizTranslationRecord(quiz: Quiz): Record<string, string> {
  const out: Record<string, string> = {};
  out[`quiz_${quiz.id}_language`] = `${quiz.language}`;
  out[`quiz_${quiz.id}_version`] = `${quiz.version}`;
  out[`quiz_${quiz.id}_title`] = quiz.title;
  out[`quiz_${quiz.id}_element`] = quiz.element;
  out[`quiz_${quiz.id}_description`] = quiz.description;
  return out;
}

export function createLessonTranslationRecord(lesson: Lesson): Record<string, string> {
  const out: Record<string, string> = {};
  out[`lesson_${lesson.id}_language`] = `${lesson.language}`;
  out[`lesson_${lesson.id}_version`] = `${lesson.version}`;
  out[`lesson_${lesson.id}_lastUpdated`] = `${lesson.lastUpdated.toString()}`;
  out[`lesson_${lesson.id}_title`] = lesson.title;
  out[`lesson_${lesson.id}_element`] = lesson.element;
  out[`lesson_${lesson.id}_description`] = lesson.description;

  if (lesson.quiz) {
    out[`lesson_${lesson.id}_quiz_id`] = lesson.quiz.id;
    out[`lesson_${lesson.id}_quiz_language`] = lesson.quiz.language;
    out[`lesson_${lesson.id}_quiz_owner_tenant`] = lesson.quiz.owner.tenant;
    out[`lesson_${lesson.id}_quiz_owner_siteId`] = lesson.quiz.owner.siteId;
    out[`lesson_${lesson.id}_quiz_version`] = lesson.quiz.version;
  }

  return out;
}

export function importSublessonTranslationRecord(
  sublesson: Sublesson,
  translation: Record<string, string>
): Sublesson {
  return {
    ...sublesson,
    title: translation[`sublesson_${sublesson.id}_title`],
    element: translation[`sublesson_${sublesson.id}_element`],
    description: translation[`sublesson_${sublesson.id}_description`],
    language: translation[`sublesson_${sublesson.id}_language`],
    version: translation[`sublesson_${sublesson.id}_version`],
    slides: sublesson.slides
      ? sublesson.slides.map((s) => {
          return {
            ...s,
            title: translation[`sublesson_${sublesson.id}_slide_${s.id}_title`],
            bodyText: translation[`sublesson_${sublesson.id}_slide_${s.id}_bodyText`],
            media: s.media
              ? s.media.map((m, i) => {
                  return {
                    ...m,
                    caption:
                      translation[`sublesson_${sublesson.id}_slide_${s.id}_media_${i}_caption`],
                    badge: translation[`sublesson_${sublesson.id}_slide_${s.id}_media_${i}_badge`],
                  };
                })
              : [],
            secondaryMedia: s.secondaryMedia
              ? s.secondaryMedia.map((m, i) => {
                  return {
                    ...m,
                    caption:
                      translation[
                        `sublesson_${sublesson.id}_slide_${s.id}_secondaryMedia_${i}_caption`
                      ],
                    badge:
                      translation[
                        `sublesson_${sublesson.id}_slide_${s.id}_secondaryMedia_${i}_badge`
                      ],
                  };
                })
              : [],
            hints: s.hints
              ? s.hints.map((h: Hint) => {
                  return {
                    ...h,
                    text: translation[
                      `sublesson_${sublesson.id}_slide_${s.id}_hint_${h.uuid}_text`
                    ],
                  };
                })
              : [],
            warnings: s.warnings
              ? s.warnings.map((w: Warning) => {
                  return {
                    ...w,
                    description:
                      translation[`sublesson_${sublesson.id}_slide_${s.id}_warning_${w.uuid}_text`],
                    source:
                      translation[
                        `sublesson_${sublesson.id}_slide_${s.id}_warning_${w.uuid}_source`
                      ],
                  };
                })
              : [],
          };
        })
      : [],
  };
}

export function createTrainingTranslationRecord(training: Training): Record<string, string> {
  const out: Record<string, string> = {};
  out[`training_${training.id}_language`] = `${training.language}`;
  out[`training_${training.id}_version`] = `${training.version}`;
  out[`training_${training.id}_lastUpdated`] = `${training.lastUpdated.toString()}`;
  out[`training_${training.id}_title`] = training.title;
  out[`training_${training.id}_description`] = training.description;
  return replaceTagsInValues(out);
}

export function importLessonTranslationRecord(
  lesson: Lesson,
  translation: Record<string, string>
): Lesson {
  return {
    ...lesson,
    title: translation[`lesson_${lesson.id}_title`],
    element: translation[`lesson_${lesson.id}_element`],
    description: translation[`lesson_${lesson.id}_description`],
    language: translation[`lesson_${lesson.id}_language`],
    version: translation[`lesson_${lesson.id}_version`],
    quiz: lesson.quiz
      ? {
          id: removeNewlines(translation[`lesson_${lesson.id}_quiz_id`]),
          version: removeNewlines(translation[`lesson_${lesson.id}_quiz_version`]),
          owner: {
            tenant: removeNewlines(translation[`lesson_${lesson.id}_quiz_owner_tenant`]),
            siteId: removeNewlines(translation[`lesson_${lesson.id}_quiz_owner_siteId`]),
          },
          language: removeNewlines(translation[`lesson_${lesson.id}_quiz_language`]),
        }
      : undefined,
  };
}

function translationRecordToXlsx(
  element: TrainingElement,
  translationRecord: Record<string, string>
) {
  const config = {
    filename: `${element.language}_${element.version}`,
    sheet: {
      data: Object.entries(translationRecord).map(([key, value]) => {
        return [
          { value: key, type: "string" },
          { value: value, type: "string" },
        ];
      }),
    },
  };

  zipcelx(config as any);
}

async function xlsxToObject(xlsxFile: File) {
  const result: Record<string, string> = {};
  await readXlsxFile(xlsxFile).then((rows) => {
    rows.forEach((r: any[]) => {
      const val = (r[1] || "").toString().replaceAll("_x000D_", "\r");
      result[r[0].toString()] = removeTags(val);
    });
  });

  return result;
}

function replaceTagsInValues(obj: Record<string, string>) {
  const newRecord: Record<string, string> = {};
  const toMd = new ToMarkdownService();
  Object.entries(obj).forEach(([key, val]) => {
    if (
      !key.includes("_language") &&
      !key.includes("_version") &&
      !key.includes("_lastUpdated") &&
      !key.includes("_title") &&
      !key.includes("_element") &&
      !key.includes("_source")
    )
      newRecord[key] = removeTags(toMd.turndown(val));
    else newRecord[key] = removeTags(val);
  });
  return newRecord;
}

function removeNewlines(str: string) {
  return str.replace(/\n/g, "");
}

function addTagsToValues(obj: Record<string, string>) {
  const newRecord: Record<string, string> = {};
  const toHtml = new ToHtmlService();
  Object.entries(obj).forEach(([key, val]) => {
    newRecord[key] = removeNewlines(val);
  });
  return newRecord;
}
